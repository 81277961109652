import {
  IconDefinition,
  faLockKeyhole,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faBolt,
  faBrainCircuit,
  faClock,
  faCoinVertical,
  faCommentDots,
  faRectangleAd,
  faSquare1,
  faThoughtBubble,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';

export type Benifit = {
  title: string;
  icon: IconDefinition;
  disabled?: boolean;
  bold?: boolean;
};

export function useBenefits() {
  const { t } = useTranslation();

  const AllBenefits: Record<string, Benifit> = {
    'Unlimited energy': {
      title: t('Unlimited energy'),
      icon: faBolt,
    },
    'Daddy energy': {
      title: t('Daddy energy'),
      icon: faBolt,
    },
    'Boyfriend energy': {
      title: t('Boyfriend energy'),
      icon: faBolt,
    },
    'Friend energy': {
      title: t('Friend energy'),
      icon: faBolt,
    },
    'Free energy': {
      title: t('Free energy'),
      icon: faBolt,
    },
    'Unlimited messages': {
      title: t('Unlimited messages'),
      icon: faCommentDots,
    },
    'Friend messages': {
      title: t('Friend messages'),
      icon: faCommentDots,
    },
    'Free messages': {
      title: t('Free messages'),
      icon: faCommentDots,
    },
    'Quicker generation': {
      title: t('Quicker generation'),
      icon: faClock,
    },
    'Master memory': {
      title: t('Master memory'),
      icon: faThoughtBubble,
    },
    'God memory': {
      title: t('God memory'),
      icon: faThoughtBubble,
    },
    'Boss memory': {
      title: t('Boss memory'),
      icon: faThoughtBubble,
    },
    'Daddy memory': {
      title: t('Daddy memory'),
      icon: faThoughtBubble,
    },
    'Boyfriend memory': {
      title: t('Boyfriend memory'),
      icon: faThoughtBubble,
    },
    'Friend memory': {
      title: t('Friend memory'),
      icon: faThoughtBubble,
    },
    'Free memory': {
      title: t('Free memory'),
      icon: faThoughtBubble,
    },
    'Starter model': {
      title: t('Starter model'),
      icon: faBrainCircuit,
    },
    'Pro model': {
      title: t('Pro model'),
      icon: faBrainCircuit,
    },
    'Ultimate model': {
      title: t('Ultimate model'),
      icon: faBrainCircuit,
    },
    'No ads': {
      title: t('No ads'),
      icon: faRectangleAd,
    },
    'Early access': {
      title: t('Early access'),
      icon: faSquare1,
    },
    'Private characters': {
      title: t('Private characters'),
      icon: faLockKeyhole,
    },
    'Monthly credits': {
      title: t('Monthly credits'),
      icon: faCoinVertical,
    },
  };

  const MappedBenefits: Record<SubscriptionLevel, Benifit[]> = {
    [SubscriptionLevel.Friend]: [
      { ...AllBenefits['Friend energy'], bold: true },
      { ...AllBenefits['Friend messages'], bold: true },
      { ...AllBenefits['Quicker generation'], disabled: true },
      AllBenefits['Friend memory'],
      AllBenefits['Pro model'],
      AllBenefits['No ads'],
      { ...AllBenefits['Early access'], disabled: true },
      { ...AllBenefits['Monthly credits'], disabled: true },
      { ...AllBenefits['Private characters'], disabled: true },
    ],
    [SubscriptionLevel.Boyfriend]: [
      { ...AllBenefits['Boyfriend energy'], bold: true },
      { ...AllBenefits['Unlimited messages'], bold: true },
      { ...AllBenefits['Quicker generation'], disabled: true },
      AllBenefits['Boyfriend memory'],
      AllBenefits['Pro model'],
      AllBenefits['No ads'],
      { ...AllBenefits['Early access'], disabled: true },
      { ...AllBenefits['Monthly credits'], disabled: true },
      { ...AllBenefits['Private characters'],},
    ],
    [SubscriptionLevel.Daddy]: [
      { ...AllBenefits['Daddy energy'], bold: true },
      { ...AllBenefits['Unlimited messages'], bold: true },
      AllBenefits['Quicker generation'],
      AllBenefits['Daddy memory'],
      AllBenefits['Pro model'],
      AllBenefits['No ads'],
      { ...AllBenefits['Early access'], disabled: true },
      { ...AllBenefits['Monthly credits'], disabled: true },
      { ...AllBenefits['Private characters'], },
    ],
    [SubscriptionLevel.Boss]: [
      { ...AllBenefits['Unlimited energy'], bold: true },
      { ...AllBenefits['Unlimited messages'], bold: true },
      AllBenefits['Quicker generation'],
      AllBenefits['Boss memory'],
      AllBenefits['Pro model'],
      AllBenefits['No ads'],
      AllBenefits['Early access'],
      { ...AllBenefits['Monthly credits'], disabled: true },
      AllBenefits['Private characters'],
    ],
    [SubscriptionLevel.Master]: [
      { ...AllBenefits['Unlimited energy'], bold: true },
      { ...AllBenefits['Unlimited messages'], bold: true },
      AllBenefits['Quicker generation'],
      AllBenefits['Master memory'],
      AllBenefits['Ultimate model'],
      AllBenefits['No ads'],
      AllBenefits['Early access'],
      AllBenefits['Monthly credits'],
      AllBenefits['Private characters'],
    ],
    [SubscriptionLevel.God]: [
      { ...AllBenefits['Unlimited energy'], bold: true },
      { ...AllBenefits['Unlimited messages'], bold: true },
      AllBenefits['Quicker generation'],
      AllBenefits['God memory'],
      AllBenefits['Ultimate model'],
      AllBenefits['No ads'],
      AllBenefits['Early access'],
      AllBenefits['Monthly credits'],
      AllBenefits['Private characters'],
    ],
  };

  return {
    MappedBenefits,
  };
}
